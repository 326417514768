import { render, staticRenderFns } from "./Nav.vue?vue&type=template&id=0b994a00&scoped=true"
import script from "./Nav.vue?vue&type=script&lang=js"
export * from "./Nav.vue?vue&type=script&lang=js"
import style0 from "./Nav.vue?vue&type=style&index=0&id=0b994a00&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b994a00",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VoyageLogo: require('/opt/buildhome/repo/components/VoyageLogo.vue').default,NextActivity: require('/opt/buildhome/repo/components/NextActivity.vue').default,Help: require('/opt/buildhome/repo/components/Help.vue').default,WelcomeVideo: require('/opt/buildhome/repo/components/WelcomeVideo.vue').default,ColorPicker: require('/opt/buildhome/repo/components/ColorPicker.vue').default,LibraryModal: require('/opt/buildhome/repo/components/library/Modal.vue').default,Profile: require('/opt/buildhome/repo/components/Profile.vue').default,Settings: require('/opt/buildhome/repo/components/Settings.vue').default,ImageBank: require('/opt/buildhome/repo/components/ImageBank.vue').default})
